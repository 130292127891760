/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CpmSectionType {
    AUDIENCE = 'audience',
    BRAND_SAFETY = 'brand-safety',
    MEDIA_BID = 'media-bid',
    CROSS_DEVICE = 'cross-device',
    MEASUREMENT = 'measurement',
}
