/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PlayerPosition {
    PRE_ROLL = 'pre-roll',
    MID_ROLL = 'mid-roll',
    POST_ROLL = 'post-roll',
    NO_ROLL = 'no-roll',
    INTERSTITIAL = 'interstitial',
    IN_ARTICLE = 'in-article',
    IN_BANNER = 'in-banner',
    IN_FEED = 'in-feed',
}
